.whatsapp-linear {
  background: linear-gradient(
    90deg,
    rgb(0 132 198) 0%,
    var(--color-primary) 100%
  );
  color: white !important;
  padding: 0px 30px;
  border-radius: 27px;
  display: flex !important;
  place-items: center;
  column-gap: 10px;
  font-size: medium;
  font-weight: 500;
  height: 55px !important;
  transition: none !important;
  text-decoration: none !important;
}

.mainmenu > li > a::before {
  transition: none !important;
  content: "";
  height: 0;
  width: 0;
  background: none !important;
  /* background-color: var(--color-primary); */
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 0;
  /* transition: 0.5s; */
}
