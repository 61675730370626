.ul-li {
  width: 207px;
  color: #5f5f5f;
  font-size: 1rem;
  margin-bottom: 12px;
  word-wrap: break-word;
  padding-right: 10px;
}
.ul-li:before {
  content: "\2022";
  color: #f16700;
  font-weight: 700;
  display: inline-block;
  width: 11px;
  font-size: 20px;
}
.maskImage {
  -webkit-mask-image: linear-gradient(black, transparent);
  mask-image: linear-gradient(black, transparent);
}

.banner .__form-container .__Form .__input-box .form-control:placeholder-shown {
  border: 1px solid rgba(44, 44, 44, 0.5);
}

.banner .__form-container .__Form .__input-box .form-control {
  border: 1px solid #2c2c2c;
  background-color: #fff8f8;
}
.banner .__form-container .__Form .__input-box input,
.banner .__form-container .__Form .__input-box select,
.banner .__form-container .__Form .__input-box textarea {
  display: block;
  padding: 6px 8px;
  font-size: 14px;
  width: 100%;
  outline: none;
  border-radius: 4px;
}
.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 1px);
  padding: 0.375rem 0.75rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (max-width: 800px) {
  .form-control .pages {
    padding: 0.5rem 0.5rem;
  }
}
.banner
  .__form-container
  .__Form
  .__input-box
  .form-control:placeholder-shown
  ~ .form_label {
  top: 10px;
  left: 17px;
  color: #1a1a1a;
  font-size: 12px;
  background: #fff;
  color: rgba(95, 95, 95, 0.5);
  font-weight: 400;
}

.banner .__form-container .__Form .__input-box label.form_label {
  position: absolute;
  top: -5px;
  left: 17px;
  display: block;
  transition: 0.2s;
  color: #1a1a1a;
  font-size: 12px;
  background-color: #fff;
  padding-left: 5px;
  padding-right: 5px;
  display: inline-block;
  z-index: 1;
  font-weight: 400;
  pointer-events: none;
}
.banner .__form-container .__Form .__input-box label {
  font-size: 14px;
  display: block;
  text-transform: capitalize;
  line-height: 1;
  font-weight: 400;
  color: #2c2c2c;
}
.form-control1{
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
label {
  /* display: inline-block; */
  margin-bottom: 0.5rem;
  font-size: 13px;
  display: flex;
  align-items: flex-start;
  text-align: left;
}
.overlay {
  position: absolute;
  background: rgba(57, 57, 57, 0.5);
  /* center overlay text */
  display: flex;
  align-items: center;
  justify-content: center;
}
